import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const getCKUAData = async () => {
	let api = apiUrl ?? '';
	console.log('api', api);
	if (window.location.href.indexOf('ckuamediastorage.z5.web.core.windows.net') > 0) {
		api = 'https://ckuamediastorage.blob.core.windows.net/data/data.json?sp=r&st=2022-04-21T02:04:03Z&se=3022-04-21T10:04:03Z&spr=https&sv=2020-08-04&sr=b&sig=0sXnOk9GCzWwu5ZttAJ7nLzmPdMjJBTF6xebDb63ww0%3D';
	}
	if (window.location.href.indexOf('ckuamediaservice.z9.web.core.windows.net') > 0) {
		api = 'https://ckuamediaservice.blob.core.windows.net/data/data.json?sp=r&st=2022-04-21T02:25:01Z&se=3022-04-21T10:25:01Z&spr=https&sv=2020-08-04&sr=b&sig=%2BHlDJNoeA4dNpU6jt4zUx6vxbvtothOq6NdU3zKGT9g%3D';
	}
	if (window.location.href.indexOf('ondemand.ckua.com') > 0) {
		api = 'https://ckuamediaservice.blob.core.windows.net/data/data.json?sp=r&st=2022-04-21T02:25:01Z&se=3022-04-21T10:25:01Z&spr=https&sv=2020-08-04&sr=b&sig=%2BHlDJNoeA4dNpU6jt4zUx6vxbvtothOq6NdU3zKGT9g%3D';
	}

	const res = await axios.get(api);
	return res.data as CKUADataDTO;
}

export default getCKUAData;