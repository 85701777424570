import React from 'react';
// import { data as playListData } from './playlistdata';
import { ReactComponent as PlaylistIcon } from 'assets/icons/icon-playlist.svg';
import moment from 'moment';

export interface IPlayListData {
	id?: number;
	segmentId?: number;
	title?: string;
	artist?: string;
	timeOffset?: string;
	album?: string;
	musicId?: number;
}

interface IProps {
	playlist: IPlayListData[]
}



const PlayList: React.FC<IProps> = (props: IProps) => {
	return (
		props.playlist.length > 0 ? (
			<React.Fragment>
				<div className="bg-tertiary-500 px-3 py-2 rounded-top">
					<PlaylistIcon className="icon-size-sm" />
					<span className="ms-3 text-white">Playlist</span>
				</div>
				{
					props.playlist.map(item => {
						// time+=item.timeOffset*60;
						return (
							<div className="d-flex flex-column bg-white border-bottom border-gray-200 pt-2 pb-2 px-3" key={item.id}>
								{/* <span className="d-block small text-gray-700">{moment.utc(time*1000).format('HH:mm:ss')}</span> */}
								<span className="d-block small text-gray-700">{item.timeOffset}</span>
								<span className="d-block fw-bold small">{item.title}</span>
								<span className="d-block small">{item.artist}</span>
								<span className="d-block small">{item.album}</span>
							</div>
						)
					})
				}
			</React.Fragment>) :
			<React.Fragment />
	)
}

export default PlayList;