import PlayerButton from 'components/buttons/PlayerButton';
import PlayerBar from 'components/ckua/PlayerBar';
import PlayerInformation from 'components/ckua/PlayerInformation';
import React, { useContext, useEffect } from 'react';
import SegmentPlaceholder from 'assets/img/Segment-Placeholder.png'
import moment from 'moment';
import { PlayerContext, PlayerState } from 'context/PlayerContext';
import { ProgrammeContext } from 'context/ProgrammeContext';
import { isValidHttpUrl } from 'utils/helper';
import DonateButton from 'components/buttons/DonateButton';
import { IconButton } from 'components/buttons';
import { ReactComponent as IconShare } from 'assets/icons/icon-player-share.svg';
import { handleShare } from 'utils/share';
import PlayList, { IPlayListData } from './PlayList';
import { SkipButtonBackward, SkipButtonFoward } from 'components/ckua/SkipButton';
import * as helper from '../../utils/helper'

const WebPlayerComponent: React.FC = () => {

	const programmeContext = useContext(ProgrammeContext);
	const playerContext = useContext(PlayerContext);
	const segmentImage = isValidHttpUrl(programmeContext?.selectedAudio?.programme?.image!) ? programmeContext?.selectedAudio?.programme?.image! : SegmentPlaceholder;

	return (
		<div className="player" id="web-player">
			<div className="d-flex flex-column player-2">
				{/* <IconButton className="position-absolute top-0 end-0 pe-2 pt-2" onClick={() => handleShare(programmeContext?.selectedAudio?.programme?.name + '')}>
				<IconShare />
			</IconButton> */}
				<div className="text-center pt-4 linear-bg" id="web-player-image">
					<DonateButton />
					<img src={segmentImage} alt="CKUA" className="img-player my-2" />
				</div>
				<div className="container-fluid align-self-center justify-content-center px-3 sticky-top-player flex-fill" id="web-sticky">
					<div className="d-flex flex-column ms-3 overflow-hidden">
						<PlayerInformation />
					</div>

					<PlayerBar
						id="WebRange"
					/>
					<div className="d-flex justify-content-between mt-2 mb-2">
						<span className="text-white me-auto small">{moment.utc(moment.duration(playerContext.positionAudio, 'seconds').asMilliseconds()).format('HH:mm:ss')}</span>
						<span className="text-gray-600 small">{moment.utc(moment.duration(playerContext.durationAudio, 'seconds').asMilliseconds()).format('HH:mm:ss')}</span>
					</div>
					<div className="d-flex justify-content-center  mb-3">
						<div className="align-self-center" >
							<SkipButtonBackward 
								onClick={() => playerContext.setManualPosition(playerContext.positionAudio - helper.SKIP_FORWARD_SECONDS)}
							/>
						</div>
						<div className="ms-4 me-4">
							<PlayerButton playState={playerContext.playerState}
								onPauseClick={() => playerContext.setPlayerState(PlayerState.paused)}
								onPlayClick={() => playerContext.setPlayerState(PlayerState.playing)}
							/>
						</div>
						<div className="align-self-center" >
							<SkipButtonFoward 
								onClick={() => playerContext.setManualPosition(playerContext.positionAudio + helper.SKIP_BACK_SECONDS)}
							/>
						</div>
					</div>
					

				</div>
				<PlayList playlist={programmeContext.selectedAudio?.playlistSong || [] as IPlayListData[]} />
			</div>
		</div>
	);
}

export default WebPlayerComponent;