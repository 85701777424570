import { faArrowCircleLeft, faArrowCircleRight, faPlay, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from 'components/buttons';
import React, { SyntheticEvent } from 'react';
import { ReactComponent as SkipForward } from 'assets/icons/icon-fast-forward-15.svg';
import { ReactComponent as SkipBackward } from 'assets/icons/icon-rewind-15.svg';
import classNames from 'classnames';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
	icon?: IconDefinition;
	iconSize?: SizeProp;
	onClick?: ((value?: {} | undefined) => void) | undefined;
	className?: string;
	btnClassName?: string;
	disabled?: boolean;
	loading?: boolean;
}

export const SkipButtonFoward: React.FC<IProps> = (props) => {
	return (
		<div className={classNames(props.className)}>
			<IconButton
				className={classNames('btn text-white ms-auto p-0 align-self-start btn-circle d-flex align-items-center justify-content-center mt-2', props.btnClassName)}
				onClick={(e) => {
					if (props.onClick) {
						props.onClick();
					}
					(e as SyntheticEvent).stopPropagation()
				}}
				icon={<SkipForward className="icon-size-lg" />}
				disabled={props.disabled || false}
				loading={props.loading}
			/>
		</div>
	)
}

export const SkipButtonBackward: React.FC<IProps> = (props) => {
	return (
		<div className={classNames(props.className)}>
			<IconButton
				className={classNames('btn text-white ms-auto p-0 align-self-start btn-circle d-flex align-items-center justify-content-center mt-2 ', props.btnClassName)}
				onClick={(e) => {
					if (props.onClick) {
						props.onClick();
					}
					(e as SyntheticEvent).stopPropagation()
				}}
				icon={<SkipBackward className="icon-size-lg" />}
				disabled={props.disabled || false}
				loading={props.loading}
			/>
		</div>
	)
}

