import { ProgrammeContext } from 'context/ProgrammeContext';
import moment from 'moment';
import React, { useContext } from 'react';
import Marquee from 'react-fast-marquee';

const PlayerInformation: React.FC = () => {
	const programmeContext = useContext(ProgrammeContext);
	const segmentName = programmeContext?.selectedAudio?.programme?.name ?? (programmeContext?.selectedAudio?.name || '[No Name]');
	const segmentDate = programmeContext?.selectedAudio?.airDate ? moment(programmeContext?.selectedAudio?.airDate).local().format('MMM DD') : undefined;
	const marqueeText = segmentDate !== undefined ? segmentName + ' - ' + segmentDate : segmentName;
	const segmentNameElement = document.getElementById('mobile-player') || null;
	let marqueeLength = 17
	if(segmentNameElement && segmentNameElement.getBoundingClientRect()){
		const size = segmentNameElement.getBoundingClientRect()
		const width = size.right - size.left;
		// console.log(width);
		if(width<768){
			marqueeLength = (width-144)/10;
			// console.log(marqueeLength);
		}
	}
	const enableMarquee = marqueeText.length > marqueeLength ? true : false;
	const hostName = programmeContext?.selectedAudio?.hostName;

	return(
		<React.Fragment>
			<div className="d-flex flex-column">
				{enableMarquee ?
					<Marquee
						gradient={false}
						delay={3}
						loop={0}
					>
						<span className="h5 d-inline-block text-white m-0 pe-4 content-fade-in">
							{marqueeText}
						</span>
					</Marquee>
					:
					<span className="h5 text-white m-0 text-nowrap content-fade-in">
						{marqueeText}
					</span>
				}
				{hostName !== undefined &&
					<span className="flex text-gray-400 text-nowrap content-fade-in">{hostName}</span>
				}
			</div>
		</React.Fragment>
	);
};

export default PlayerInformation;