import * as React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { Spinner } from 'components/common';

interface IProps {
	onClick?: (value?: {}) => void;
	icon?: {};
	children?: {};
	link?: string;
	title?: string;
	className?: string;
	style?: {};
	type?: string;
	disabled?: boolean;
	loading?: boolean;
}

const IconButton: React.FC<IProps> = (props) => {
	const Button = (
		<button
			style={props.style ? props.style : {}}
			type={props.type || 'button'}
			className={classNames('btn btn-icon btn-link btn-pd-fix', {'btn-loading': props.loading}, props.className)}
			disabled={props.disabled || props.loading}
			onClick={props.onClick}
		>
			{props.loading && <Spinner className="spinner-small" />}
			<div className={classNames('btn-content', {'invisible': props.loading})}>
				{props.icon}
				{props.children}
			</div>
		</button>
	);

	if (props.title) {
		return (
			<Tooltip title={props.title}>
				{Button}
			</Tooltip>
		);
	}

	return Button;
}

export default IconButton;
