const hostSortFunction = (a: HostView, b: HostView) => {
	if (a.name! < b.name!) {
		return -1;
	} else if (a.name! > b.name!) {
		return 1;
	}
	else { return 0; }
}
const programmeSortFunction = (a: ProgrammeView, b: ProgrammeView) => {
	if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
		return -1;
	} else if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
		return 1;
	}
	else { return 0; }
}
export const searchHosts = (hosts: HostView[], searchText: string) => {
	const filteredResult = hosts.sort(hostSortFunction).filter(item => (
		item.name &&
		(item.name.toLowerCase().includes(searchText.toLowerCase()))
	));
	return filteredResult;
}

export const searchShows = (programmes: ProgrammeView[], segments: SegmentView[], searchText: string) => {
	const result: { programme: ProgrammeView, segments: SegmentView[] }[] = [];
	const filterResults = programmes.sort(programmeSortFunction).filter(item =>
		item.name &&
		(item.name.toLowerCase().includes(searchText.toLowerCase()) ||
			(item.hosts &&
				item.hosts?.filter(host => (host.name?.toLowerCase().includes(searchText.toLowerCase()))).length > 0
			)
		)
	);
	filterResults.forEach((item) => {
		if (item.name) {
			const filteredSegments = segments?.filter((segment) => (
				segment.programme?.name === item.name
			));
			result.push({
				programme: item,
				segments: filteredSegments
			});
		}
	});
	return result;
}