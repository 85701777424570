import { faPlay, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from 'components/buttons';
import React, { SyntheticEvent } from 'react';

import classNames from 'classnames';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
	icon?: IconDefinition;
	iconSize?: SizeProp;
	onClick?: ((value?: {} | undefined) => void) | undefined;
	className?: string;
	btnClassName?: string;
	disabled?: boolean;
	loading?: boolean;
}

const PlayButton: React.FC<IProps> = (props) => {
	return (
		<div className={classNames(props.className)}>
			<IconButton
				className={classNames('btn-secondary text-white ms-auto p-0 align-self-start btn-circle d-flex align-items-center justify-content-center', props.btnClassName)}
				onClick={(e) => {
					if(props.onClick){
						props.onClick();
					}
					(e as SyntheticEvent).stopPropagation()}}
				icon={<FontAwesomeIcon size={props.iconSize || 'xs'} icon={props.icon || faPlay} />}
				disabled={props.disabled || false}
				loading={props.loading}
			/>
		</div>
	)
}

export default PlayButton;