import React, { createContext } from 'react';
import ReactGA from 'react-ga4';
export enum PlayerState {
	playing,
	paused,
	starting,
	buffering,
	seeking,
	inactive,
}

interface IPlayerContext {
	playerState: PlayerState;
	setPlayerState: (value: React.SetStateAction<PlayerState>) => void;
	activeCardId: string | undefined;
	setActiveCardId: (value: React.SetStateAction<string | undefined>) => void;
	durationAudio: number;
	setDurationAudio: (value: React.SetStateAction<number>) => void;
	positionAudio: number;
	setPositionAudio: (value: React.SetStateAction<number>) => void;
	manualPosition: number | undefined;
	setManualPosition: (value: React.SetStateAction<number | undefined>) => void;
	togglePlayPause: () => void;
	playerLarge: boolean;
	setPlayerLarge: (value: boolean) => void;
}
const PlayerContext = createContext<IPlayerContext>({} as any);

const PlayerProvider = (props: any) => {
	const [playerState, tempSetPlayerState] = React.useState<PlayerState>(PlayerState.paused);
	const setPlayerState = (state: React.SetStateAction<PlayerState>) => {
		let stateString = PlayerState[parseInt(state as unknown as string, 0)];
		ReactGA.event({
			category: 'Player',
			action: stateString,
		});
		tempSetPlayerState(state);
	}
	//const [playerState, setPlayerState] = React.useState<PlayerState>(PlayerState.paused);
	const [activeCardId, setActiveCardId] = React.useState<string | undefined>(undefined);

	const [durationAudio, setDurationAudio] = React.useState<number>(0.0);
	const [positionAudio, setPositionAudio] = React.useState<number>(0.0);
	const [manualPosition, setManualPosition] = React.useState<number | undefined>(undefined);

	const [playerLarge, setPlayerLarge] = React.useState<boolean>(false);

	//Auto Play segment when selected from card
	React.useEffect(() => {
		if (activeCardId !== undefined) {
			setPlayerState(PlayerState.playing);
		}
	}, [activeCardId]);

	return (
		<PlayerContext.Provider
			value={{
				playerState,
				setPlayerState,
				activeCardId,
				setActiveCardId,
				durationAudio,
				setDurationAudio,
				positionAudio,
				setPositionAudio,
				manualPosition,
				setManualPosition,
				togglePlayPause,
				playerLarge,
				setPlayerLarge: (value) => {
					if (value === true) {
						document.body.classList.add('overflow-hidden');
					} else {
						document.body.classList.remove('overflow-hidden');
					}
					setPlayerLarge(value);
				}
			}}
		>
			{props.children}
		</PlayerContext.Provider>
	)

	function togglePlayPause() {
		if (playerState === PlayerState.playing) {
			setPlayerState(PlayerState.paused);
		} else {
			setPlayerState(PlayerState.playing);
		}
	}
}

export {
	PlayerContext,
}

export default PlayerProvider;