import classNames from 'classnames';
import { PlayerContext, PlayerState } from 'context/PlayerContext';
import React, { useContext } from 'react';

interface IProps {
	id: string;
	className?: string;
}

const PlayerBar: React.FC<IProps> = (props: IProps) => {
	const playerContext = useContext(PlayerContext);
	React.useEffect(() => {
		updateSlider();
	// eslint-disable-next-line
	}, [playerContext.positionAudio])

	return(
		<React.Fragment>
			<input
				id={props.id}
				type="range"
				min="0"
				max={playerContext.durationAudio}
				value={playerContext.positionAudio}
				onChange={handleScrubberChange}
				onMouseDown={handleScrubberEventStart}
				onTouchStart={handleScrubberEventStart}
				onMouseUp={handleScrubberEventEnd}
				onTouchEnd={handleScrubberEventEnd}
				className={classNames('container-fluid form-range', props.className)}
			/>
		</React.Fragment>
	);

	function updateSlider() {
		if (playerContext.positionAudio >= 0 && playerContext.durationAudio > 0) {
			const value = (playerContext.positionAudio)/(playerContext.durationAudio)*100 + '%'
			if (document.getElementById(props.id) !== null) {
				document.getElementById(props.id)!.style.setProperty('--sliderValue', value);
			}
		}
	}

	function handleScrubberChange(e: any) {
		const value = Number(e.target.value);
		playerContext.setPositionAudio(value);
		playerContext.setManualPosition(value);
	}

	function handleScrubberEventEnd() {
		playerContext.setPlayerState(PlayerState.playing);
	}

	function handleScrubberEventStart() {
		playerContext.setPlayerState(PlayerState.paused);
	}

};

export default PlayerBar;