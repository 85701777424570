import React from 'react';
import ExpiringSoon from 'components/ckua/ExpiringSoon';
import FeaturedHosts from 'components/ckua/FeaturedHosts';
import RecentlyAdded from 'components/ckua/RecentlyAdded';
import WelcomeBanner from 'components/homePage/WelcomeBanner';
import DonateButton from 'components/buttons/DonateButton';

interface IProps {
	className?: string;
}

const LandingPage = (props: IProps) => {
	return (
		<React.Fragment>
			<WelcomeBanner />
			<RecentlyAdded />
			<ExpiringSoon />
			<FeaturedHosts />
			<div className="d-flex d-md-none justify-content-center py-4">
				<DonateButton />
			</div>
		</React.Fragment>
	)
};

export default LandingPage;




