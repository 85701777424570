import * as React from 'react';
import classNames from 'classnames';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { IconChevronLeft, IconChevronRight } from '../../components/icons';
import { Spinner } from '.';
import { IconButton } from '../buttons';

interface IProps {
	className?: string;
	filterable?: boolean;
	TableComponent?: React.ReactType;
	columns?: any[];
	data?: any[];
	getTableProps?: (...args: any[]) => {};
	getTheadFilterThProps?: (...args: any[]) => {};
	getTheadGroupProps?: (...args: any[]) => {};
	getTheadProps?: (...args: any[]) => {};
	getTheadThProps?: (...args: any[]) => {};
	getTbodyProps?: (...args: any[]) => {};
	getTrProps?: (...args: any[]) => {};
	getTdProps?: (...args: any[]) => {};
	minRows?: number;
	hasActions?: boolean;
	resizable?: boolean;
	sortable?: boolean;
	filtered?: any[];
	onFilteredChange?: any;
	noDataText?: string;
	showPagination?: boolean;
	onPageChange?: (pageIndex: number) => void;
	page?: number;
	// handles clicks on each data table row, if not provided nothing happens
	onRowClick?: ({ row, original }: { row?: any, original?: any }) => void;
	loading?: boolean;
}

class DataTable extends React.Component<IProps> {
	public onPageChange = (e: any, callback: () => void) => {
		// TODO: Find a better way to animate the page
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		callback();
	}

	public getTrProps = (_: any, rowInfo: any) => {
		const { onRowClick } = this.props;
		if (onRowClick) {
			return {
				className: 'tr cursor-pointer',
				onClick(e: any, handleOriginal: any) {
					onRowClick(rowInfo);
					if (handleOriginal) {
						handleOriginal();
					}
				}
			};
		} else {
			return {
				className: 'tr '
			};
		}
	}

	public render() {
		const {
			className,
			filterable,
			hasActions,
			TableComponent,
			columns,
			data,
			showPagination = true,
			getTableProps = () => {
				return { className: 'table' };
			},
			getTheadFilterThProps = () => {
				return { className: 'th' };
			},
			getTheadGroupProps = () => {
				return { className: 'thead theadgroup' };
			},
			getTheadProps = () => {
				return { className: 'thead' };
			},
			getTheadThProps = () => {
				return { className: 'th' };
			},
			getTbodyProps = () => {
				return { className: 'tbody' };
			},
			getTdProps = () => {
				return { className: 'td' };
			},
			minRows = 0,
			resizable = false,
			sortable = false,
			noDataText,
			filtered,
			onFilteredChange,
			onPageChange,
			page,
			loading,
		} = this.props;
		return (
			<ReactTable
				className={classNames('data-table', hasActions !== false && 'has-actions', className)}
				filterable={filterable}
				onFilteredChange={onFilteredChange}
				filtered={filtered}
				defaultFilterMethod={(filter, row) => {
					return String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase());
				}}
				TableComponent={TableComponent}
				columns={columns}
				data={data}
				showPagination={showPagination}
				getTableProps={getTableProps}
				getTheadFilterThProps={getTheadFilterThProps}
				getTheadGroupProps={getTheadGroupProps}
				getTheadProps={getTheadProps}
				getTheadThProps={getTheadThProps}
				getTbodyProps={getTbodyProps}
				getTrProps={this.getTrProps}
				getTdProps={getTdProps}
				minRows={minRows}
				resizable={resizable}
				loading={loading}
				LoadingComponent={() => loading ? <Spinner className={'spinner-large'} /> : null}
				sortable={sortable}
				PreviousComponent={p => (
					<IconButton
						onClick={(e: any) => this.onPageChange(e, p.onClick)}
						disabled={p.disabled}
						className="btn-link"
					>
						<IconChevronLeft />
					</IconButton>
				)}
				NextComponent={p => (
					<IconButton
						onClick={(e: any) => this.onPageChange(e, p.onClick)}
						disabled={p.disabled}
						className="btn-link"
					>
						<IconChevronRight />
					</IconButton>
				)}
				onPageChange={onPageChange}
				noDataText={!loading && noDataText}
				page={page}
			/>
		);
	}
}

export default DataTable;