import PlayerButton from 'components/buttons/PlayerButton';
import PlayerBar from 'components/ckua/PlayerBar';
import PlayerInformation from 'components/ckua/PlayerInformation';
import React, { useContext, useEffect, useState } from 'react';
import SegmentPlaceholder from 'assets/img/Segment-Placeholder.png'
import { PlayerContext, PlayerState } from 'context/PlayerContext';
import classNames from 'classnames';
import { ProgrammeContext } from 'context/ProgrammeContext';
import { isValidHttpUrl } from 'utils/helper';
import DonateButton from 'components/buttons/DonateButton';
import moment from 'moment';
import { IconButton } from 'components/buttons';
import { handleShare } from 'utils/share';
import { ReactComponent as IconShare } from 'assets/icons/icon-player-share.svg';
import PlayList, { IPlayListData } from './PlayList';
import UpwardArrow from 'assets/img/upward-arrow.svg';
import DownwardArrow from 'assets/img/downward-arrow.svg';
import { SkipButtonBackward, SkipButtonFoward } from 'components/ckua/SkipButton';
import * as helper from '../../utils/helper'
const isIPhone = !navigator.userAgent.match(/Android/) && (navigator.userAgent.match(/iphone/i)) != null

const MobilePlayerComponent: React.FC = () => {
	const programmeContext = useContext(ProgrammeContext);
	const playerContext = useContext(PlayerContext);
	const segmentImage = isValidHttpUrl(programmeContext?.selectedAudio?.programme?.image!) ? programmeContext?.selectedAudio?.programme?.image! : SegmentPlaceholder;
	const { playerLarge, setPlayerLarge } = playerContext;
	const [isTouchingPlayButton, setIsTouchingPlayButton] = useState<Boolean>(false);
	
	
	return (
		<div
			className={classNames('player-mobile',
				playerLarge === true ? 'player-mobile-modifier' : 'bg-gray-800 player-mobile-swipe',
				playerLarge && (isIPhone ? 'player-mobile-iphone-height' : 'player-mobile-height'))}
			id="mobile-player"
			onClick={(e) => {
				// alert(JSON.stringify(e));
				if (!playerLarge) {
					// e.stopPropagation();
					setPlayerLarge(true);
				}
			}}
		>
			{playerLarge &&
				<div className="d-flex flex-column align-self-center position-absolute top-0 end-0 me-2 mt-3" onClick={() => handleShare(programmeContext?.selectedAudio?.programme?.name + '')}>
					<IconButton className=" content-fade-in">
						<IconShare />
					</IconButton>
					<div className="text-white text-center">Share</div>
				</div>}
			{/* <button className={classNames('mb-0 expand-bar', playerLarge ? 'position-sticky' : 'position-relative')}
				onClick={() => {
					setPlayerLarge(!playerLarge);
				}}
				onTouchEnd={() => {
					document.body.style.overflow = 'scroll';
				}}
			/> */}
			<div
				className={classNames('mb-0 text-center',
					playerLarge ? 'position-sticky pb-3' : 'position-relative')}
				onClick={() => {
					if (playerLarge) { setPlayerLarge(false); }
				}}
			>
				{playerLarge ?
					<img src={DownwardArrow} alt="Collapse" /> :
					<img src={UpwardArrow} alt="Expand" />
				}
			</div>
			<div className={classNames('d-flex justify-content-center',
				playerLarge === true ?
					'mt-auto px-3 flex-column linear-bg player-mobile-swipe' :
					'flex-fill px-3 bg-gray-800')}
			>
				<div
					className={
						classNames('position-static flex-column',
							playerLarge === true ? 'text-center mt-3 ' : '')}
				>
					{playerLarge === true &&
						<div className={classNames('content-fade-in')}>
							<DonateButton />
						</div>
					}
					{
						playerLarge === true &&
						<img src={segmentImage} alt="CKUA" className={classNames('', 'img-player-lg my-2')} />
					}

				</div>

				{/* Play button and Information - rerendering for fade in on player change */}
				{playerLarge === false &&
					<div className="d-flex flex-fill mw-0 align-items-center py-1">
						<img src={segmentImage} alt="CKUA" className={classNames('', 'img-player-sm')} />
						<div className="flex-fill overflow-hidden px-3">
							<PlayerInformation />
						</div>
						<div
							className="content-fade-in"
							id="player-min-play-btn"
							// onClick={e=>e.preventDefault()}
						>
							<PlayerButton
								playState={playerContext.playerState}
								onPauseClick={() => {
									playerContext.setPlayerState(PlayerState.paused);
								}}
								onPlayClick={() => playerContext.setPlayerState(PlayerState.playing)}
							/>
						</div>
					</div>
				}
			</div>
			{playerLarge === false &&
				<PlayerBar
					id="MobileRange"
					className="player-transition"
				/>
			}
			{playerLarge &&
				<div className={classNames('sticky-top-player mt-auto px-3 d-flex flex-column flex-fill')} id="mobile-sticky">
					{
						playerLarge === true &&
						<div className="d-flex">
							<div className="flex-fill overflow-hidden ps-3">
								<PlayerInformation />
							</div>
						</div>
					}
					<PlayerBar
						id="MobileRange"
						className="mobile-modifier py-3 player-transition"
					/>
					{playerLarge === true &&
						<div className="d-flex justify-content-between player-transition content-fade-in mt-1 mb-1">
							<span className="text-white me-auto small">{moment.utc(moment.duration(playerContext.positionAudio, 'seconds').asMilliseconds()).format('HH:mm:ss')}</span>
							<span className="text-gray-600 small">{moment.utc(moment.duration(playerContext.durationAudio, 'seconds').asMilliseconds()).format('HH:mm:ss')}</span>
						</div>
					}
					<div className="d-flex justify-content-center  mb-3">
						<div className="align-self-center" >
							<SkipButtonBackward
								onClick={() => playerContext.setManualPosition(playerContext.positionAudio - helper.SKIP_FORWARD_SECONDS)}
							/>
						</div>
						<div className="ms-4 me-4">
							<PlayerButton playState={playerContext.playerState}
								onPauseClick={() => playerContext.setPlayerState(PlayerState.paused)}
								onPlayClick={() => playerContext.setPlayerState(PlayerState.playing)}
							/>
						</div>
						<div className="align-self-center" >
							<SkipButtonFoward
								onClick={() => playerContext.setManualPosition(playerContext.positionAudio + helper.SKIP_BACK_SECONDS)}
							/>
						</div>
					</div>
				</div>
			}

			{playerLarge === true &&
				<PlayList playlist={programmeContext.selectedAudio?.playlistSong || [] as IPlayListData[]} />
			}

		</div>
	);
}

export default MobilePlayerComponent;