import * as React from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import PageHeader from 'components/common/PageHeader';
import { ReactComponent as Search } from 'assets/icons/icon-search.svg';
import { ReactComponent as Record } from 'assets/icons/icon-record.svg';

//#region component imports
import FourOhFourPage from './common/404Page';
import LandingPage from './common/LandingPage';
import PlayerComponent from 'components/player/PlayerComponent';
//#endregion

import SearchPage from 'common/SearchPage';
import classNames from 'classnames';
import { PlayerContext } from 'context/PlayerContext';
import WebPlayerComponent from 'components/player/WebPlayerComponent';
import MobilePlayerComponent from 'components/player/MobilePlayerComponent';

const Routes: React.FC = () => {
	const location = useLocation();
	const searchShowing = location?.pathname.includes('search') ? true : false;
	const playerContext = React.useContext(PlayerContext);
	const { playerLarge, setPlayerLarge } = playerContext;
	return (
		<React.Fragment>
			<div className="d-flex flex-column flex-md-row">
				<div className="flex-fill">
					<PageHeader />
					<Switch>
						<Route exact={true} path="/" component={LandingPage} />
						<Route exact={true} path="/search" component={SearchPage} />
						<Route exact={true} path="/search/:parameter" component={SearchPage} />
						<Route exact={true} path="/search/:parameter/:keyword" component={SearchPage} />
						<Route path="*" component={FourOhFourPage} />
					</Switch>
				</div>
				<PlayerComponent />
			</div>
		</React.Fragment>
	);
}

export default Routes;
