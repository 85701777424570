import React from 'react';
import HostPlaceholder from 'assets/img/Host-Placeholder.png'
import classNames from 'classnames';
import PlayButton from './PlayButton';
import { isValidHttpUrl } from 'utils/helper';
import { PlayerContext, PlayerState } from 'context/PlayerContext';
import { ProgrammeContext } from 'context/ProgrammeContext';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';

interface IProps {
	id: string;
	className?: string;
	style?: {};
	host: HostView;
	simple: boolean;
}

const HostCard: React.FC<IProps> = (props) => {
	const { host, id } = props;
	const programmeContext = React.useContext(ProgrammeContext);
	const playerContext = React.useContext(PlayerContext);
	const cardId = 'host-' + id;
	const hostImage = host.image && isValidHttpUrl(host.image!) ? host.image! : HostPlaceholder;
	const buttonIcon = (playerContext.playerState === PlayerState.playing && playerContext.activeCardId === cardId) ? faPause : faPlay;

	return (
		<div className={classNames('d-flex flex-column bg-white rounded-3 p-2 my-1 card-drop-shadow w-100', props.className)}>
			<div className="d-flex align-items-center">
				<div
					className="d-flex position-relative me-1"
					onClick={() => programmeContext.updateSelectedAudio(cardId, host)}
				>
					<img src={hostImage} alt="CKUA" className="img-host" />
					{
						host.streamURL && isValidHttpUrl(host.streamURL) &&
						<PlayButton
							className="position-absolute top-0 start-100 translate-middle-x"
							icon={buttonIcon}
							onClick={() => programmeContext.updateSelectedAudio(cardId, host)}
						/>
					}
				</div>
				<div className="ps-3 justify-content-center">
					{props.simple ?
						<Link
							to={`/search/hosts/${encodeURIComponent(props.host.name + '')}`}
							className="d-block fw-bold small text-dark"
						>
							{host.name}
						</Link>
						:
						<span className="d-block fw-bold small">{host.name}</span>
					}
					{!props.simple &&
						<span className="d-block small">{host.description}</span>}
					{!props.simple &&
						<Link to={{ pathname: host.hostInfoURL || 'https://ckua.com/on-air/hosts/' }} target="_blank" rel="noopener noreferrer" className="text-primary-600">Learn More</Link>
					}
				</div>
			</div>
		</div>
	);
}

export default HostCard;