import { ProgrammeContext } from 'context/ProgrammeContext';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import HostCard from './HostCard';

interface IProps {
}

const FeaturedHosts: React.FC<IProps> = (props) => {
	const programmeContext = useContext(ProgrammeContext);

	return (
		<React.Fragment>
			<div className="bg-primary-600 py-3">
				<div className="container d-flex flex-column bg-primary-600 pt-3 pb-3">
					<div className="d-flex flex-row">
						<h2 className="text-white me-auto" >Featured Hosts</h2>
						<Link to="/search/hosts" className="text-secondary-400">all hosts</Link>
					</div>
					<span className="text-white">Get to know a little about the hosts behind the shows</span>
					<div className="row gx-3 mt-3">
						{programmeContext.featuredHost?.map((item)=> {
							const cardKey = `featured-hostCard-${item.id}`;
							return (
								<div className="col-md-6 col-lg-4 d-md-flex" key={cardKey}>
									<HostCard host={item} id={cardKey} simple={true} />
								</div>
							);
						}

						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default FeaturedHosts;