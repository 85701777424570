import React, { useCallback } from 'react';
import { message } from 'antd';

type IsMounted = () => boolean;
type Effect = (isMounted: IsMounted) => Promise<any>;

const useEffectAsync = (effect: Effect) => {

	const memoizedEffect = useCallback(effect, []);

	React.useEffect(() => {

		let mounted: boolean = true;

		const isMounted: IsMounted = () => mounted;

		try {
			memoizedEffect(isMounted);
		} catch (error) {
			const { message: msg } = error && error.response && error.response.data;
			if (msg) {
				message.error({
					message: msg
				});
			}
		}

		return () => {
			mounted = false;
		};

	}, [memoizedEffect]);

};

export default useEffectAsync;