import React from 'react';
import SegmentCard from 'components/ckua/SegmentCard';
import { Link } from 'react-router-dom';

interface IProps {
	programme: ProgrammeView
	segments?: SegmentView[]
}

const SearchResultSection: React.FC<IProps> = (props) => {
	return (
		(
			<>
				{props.segments && props.segments?.length > 0 ?
					<div className="mb-4">
						<div className="d-flex justify-content-between">
							<h6>{props.programme.name}</h6>
							<Link to={{ pathname: props.programme.programmeInfoURL || 'https://ckua.com/on-air/shows/' }} target="_blank" rel="noopener noreferrer" className="text-primary-600">Learn More</Link>
						</div>
						<hr className="mt-1 divider-beige" />
						{props.segments?.map((item) => {
							const cardKey = `search-segmentCard-${item.id}`;
							return (
								<div key={cardKey}>
									<SegmentCard segment={item} id={cardKey} />
								</div>
							);
						})}
					</div>
					:
					<React.Fragment />
				}
			</>
		)
	);
}

export default SearchResultSection;
