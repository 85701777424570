import * as React from 'react';
import { Link } from 'react-router-dom';
import { Spin, Icon } from 'antd';
import classNames from 'classnames';

interface IProps {
	onClick?: (e?: any) => any;
	children?: {};
	aux?: boolean;
	to?: string | {};
	type?: string;
	disabled?: boolean;
	className?: string;
	tabIndex?: number;
	loading?: boolean;
	style?: {};
}

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin={true} />;

const Button: React.StatelessComponent<IProps> = ({
	to,
	onClick,
	className,
	type,
	disabled,
	children,
	tabIndex,
	loading,
	...props
}) => {
	if (to && !disabled) {

		return (
			<Link onClick={onClick} to={to} className={classNames('btn', className)} tabIndex={tabIndex} {...props}>
				<span className="btn-content">{children}</span>
				{loading && <Spin indicator={antIcon}/>}
			</Link>
		);
	} else {
		return (
			<button
				onClick={onClick}
				className={classNames('btn', className, {'btn-loading': loading})}
				type={type}
				disabled={disabled || loading}
				tabIndex={tabIndex}
				{...props}
			>
				{loading && <Spin indicator={antIcon} className="ant-btn-spin" />}
				<span className="btn-content">{children}</span>
			</button>
		);
	}
};

export default Button;
