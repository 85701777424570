export  const isValidHttpUrl = (text: string) => {
	let url;

	try {
		url = new URL(text);
	} catch (_) {
		return false;
	}

	return url.protocol === 'http:' || url.protocol === 'https:';
}





export	const SKIP_BACK_SECONDS= 15;
export	const SKIP_FORWARD_SECONDS = 15;
