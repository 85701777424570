import * as React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import classNames from 'classnames';

interface IProps {
	onClick?: (value?: {}) => void;
	children?: {};
	to: string | {};
	title?: string;
	className?: string;
	style?: {};
	disabled?: boolean;
}

class IconLinkButton extends React.Component<IProps> {
	public render() {
		const LinkElement = (
			<Link
				to={this.props.to}
				style={this.props.style ? this.props.style : {}}
				className={classNames('btn btn-icon', this.props.className, this.props.disabled ? 'disabled' : '')}
				onClick={(e: any) => {
					e.stopPropagation();
					return this.props.onClick && this.props.onClick();
				}}
			>
				{this.props.children}
			</Link>
		);

		if (this.props.title) {
			return (
				<Tooltip title={this.props.title}>
					{LinkElement}
				</Tooltip>
			);
		}

		return LinkElement;
	}
}

export default IconLinkButton;
