import React, { SyntheticEvent } from 'react';
import { faPause, faPlay, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { PlayerState } from 'context/PlayerContext';
import PlayButton from 'components/ckua/PlayButton';

interface IPlayButtonProps {
	onPauseClick: () => void;
	onPlayClick: () => void;
	playState: PlayerState;
}

const PlayerButton: React.FC<IPlayButtonProps> = ({ playState = PlayerState.paused, onPauseClick, onPlayClick }) => {
	const playBtn =
		<PlayButton icon={faPlay} iconSize="lg"
			onClick={onPlayClick}
			btnClassName="btn-circle-lg"
		/>
	const pauseBtn =
		<PlayButton icon={faPause} iconSize="lg"
			onClick={onPauseClick}
			btnClassName="btn-circle-lg" />
	const loadingBtn =
		<PlayButton icon={faPlay} iconSize="lg" loading={true} btnClassName="btn-circle-lg" />
	const disabledBtn =
		<PlayButton icon={faTimesCircle} iconSize="lg"
			onClick={onPauseClick}
			btnClassName="btn-circle-lg" disabled={true} />

	switch (playState) {
		case PlayerState.playing:
			return (pauseBtn)
		case PlayerState.paused:
			return (playBtn)
		// case PlayerState.starting:
		// case PlayerState.buffering:
		case PlayerState.seeking:
			return (loadingBtn)
		// case PlayerState.inactive:
		default:
			return (disabledBtn)
	}
}

export default PlayerButton;