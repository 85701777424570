import React from 'react'
// import loader from './loader';
import { RefObject } from 'react';
import { PlayerState } from 'context/PlayerContext';

interface IAzurePlayerProps {
	source: string;
	skin?: string;
	options?: any
	playerState: PlayerState;
	setPositionAudio: React.Dispatch<React.SetStateAction<number>>;
	setDurationAudio: React.Dispatch<React.SetStateAction<number>>;
	manualPosition: number | undefined;

}

const DEFAULT_SKIN = 'amp-flush';
const DEFAULT_RATIO = [16, 9];
const DEFAULT_OPTIONS = {
	controls: true,
	autoplay: false,
	width: '640',
	height: '400',
	'nativeControlsForTouch': false,
	'playsInline': true,
	muted: false,
	logo: {
		enabled: false
	},
};
declare const window: any;

export default class AzurePlayer extends React.Component<IAzurePlayerProps, any>{

	public static defaultProps = {
		skin: DEFAULT_SKIN,
		className: '',
		adaptRatio: DEFAULT_RATIO,
		options: DEFAULT_OPTIONS,
	}

	videoNode: RefObject<any>;
	player: any;
	initialization: any;


	constructor(props: IAzurePlayerProps) {
		super(props);
		this.videoNode = React.createRef();
		this.state = { readyToPlay: false };
	}

	componentWillUnmount() {
		this._destroyPlayer();
	}

	componentDidMount() {
		this._createPlayer(() => {
			this._setVideo();
		});
		this.player.addEventListener('durationchange', () => {
			this.props.setDurationAudio(this.player.duration());
		});
		this.player.addEventListener('canplaythrough', () => {
			this.setState({ readyToPlay: true })
			// console.log('canplaythrough')
			if (this.props.playerState === PlayerState.playing) {
				this.player.play();
			}
		})
		// this.player.addEventListener('loadeddata', () => {
		// 	console.log('loadeddata')
		// })
		// this.player.addEventListener('loadedmetadata', () => {
		// 	console.log('loadedmetadata')
		// })
		// this.player.addEventListener('waiting', () => {
		// 	console.log('waiting')
		// })
		this.player.addEventListener('timeupdate', () => {
			this.props.setPositionAudio(this.player.currentTime());
		});
		// this.player.addEventListener('error', (e: any) => {
		// 	console.log(this.errorInfo(this.player.error()))
		// });
	}
	errorInfo(err: any) {

		var errMsg = this.highlevelError(err.code);
		errMsg += ' code: ' + err.code.toString(16);

		if (err.message) {
			errMsg += ' msg: ' + err.message;
		}

		return errMsg;
	}

	highlevelError(errorCode: any) {
		var errorDesc;
		var uiCodeMask = 0xff00000;
		var uiCode = (errorCode & uiCodeMask) >> 20;
		switch (uiCode) {
			case 0:
				errorDesc = 'MEDIA_ERR_CUSTOM'
				break;
			case 1:
				errorDesc = 'MEDIA_ERR_ABORTED';
				break;
			case 2:
				errorDesc = 'MEDIA_ERR_NETWORK';
				break;
			case 3:
				errorDesc = 'MEDIA_ERR_DECODE';
				break;
			case 4:
				errorDesc = 'MEDIA_ERR_SRC_NOT_SUPPORTED';
				break;
			case 5:
				errorDesc = 'MEDIA_ERR_ENCRYPTED';
				break;
			case 6:
				errorDesc = 'SRC_PLAYER_MISMATCH';
				break;
			default:
				errorDesc = 'MEDIA_ERR_UNKNOWN';
		}

		return errorDesc;
	}


	componentDidUpdate(prevProps: IAzurePlayerProps) {
		if (prevProps.source !== this.props.source) {
			this._setVideo();
		}
		if (prevProps.playerState !== this.props.playerState) {
			if (this.props.playerState === PlayerState.playing) {
				// Only call pay if we are ready to play, otherwise it will autoplay when loaded
				if (this.state.readyToPlay) {
					this._playVideo();
				}



			}
			if (this.props.playerState === PlayerState.paused) {
				this._pauseVideo();
			}
		}

		if (this.props.manualPosition !== undefined && prevProps.manualPosition !== this.props.manualPosition) {
			try {
				this.player.currentTime(Number(this.props.manualPosition));
			} catch (err) {
				console.log('There was an error trying to set the position.')
				console.log('Timer Error: ', err);
			}
		}
	}

	_destroyPlayer() {
		this.player && this.player.dispose();
	}

	_setVideo() {
		try {
			this.setState({ readyToPlay: false })
			const newSource = { src: this.props.source }
			if (this.player) {
				this.player.src(newSource);
				this.player.type = 'application/vnd.ms-sstr+xml'

			}
			//	alert('wait set video' + this.props.source)
		} catch (err) {

			console.log('Set Episode Error: ', err);
		}
	}
	_playVideo() {
		try {
			this.player.play();

		} catch (err) {
			console.log(`src: ${this.player.currentSrc()}`)
			console.log('Play Episode Error: ', err);
		}
	}
	_stopVideo() {
		this.player.stop();
	}
	_pauseVideo() {
		this.player.pause();
	}
	_createPlayer(callback: Function) {
		if (this.videoNode.current && (typeof (window.amp) === 'function')) {
			this.player = window.amp(this.videoNode.current, DEFAULT_OPTIONS, callback);
		}
	}

	render() {
		return (
			<div>
				<video playsInline
					ref={this.videoNode}
				// data-setup='{"traceConfig": {"TraceTargets": [{ "target": "console" }], "maxLogLevel": 3 }}'
				/>
			</div>
		);
	}
}
