import React from 'react';
import { ReactComponent as Search } from 'assets/icons/icon-search.svg';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';

interface IProps {
}

export interface IParams {
	parameter?: 'hosts' | 'shows' | undefined | null | string;
	keyword?: string | undefined | null;
}

const SearchBar: React.FC<IProps> = (_props: IProps) => {
	const params: IParams = useParams();
	const history = useHistory();
	const showsOrHosts = params?.parameter || '';
	const searchKeyword = params?.keyword ? decodeURIComponent(params?.keyword || '') : '';

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUrl(e.target.value)
	}
	const handleSearchFocus = () => {
		window.scrollTo({
			top: 120,
			left: 0,
		});
	}
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.currentTarget.blur();
		}
	}
	return (
		<div className="d-flex flex-column">
			<div className="d-flex">
				<SearchToggleButton
					onClick={() => toggleParam('shows')}
					title="Shows"
					active={showsOrHosts !== 'hosts'}
				/>
				<SearchToggleButton
					onClick={() => toggleParam('hosts')}
					title="Hosts"
					active={showsOrHosts === 'hosts'}
				/>
			</div>
			<div className="shadow-sm container-fluid d-flex bg-white search-bar-radius p-3 mt-1">
				<Search />
				<input
					className="container border-0 no-outline"
					type="text"
					value={searchKeyword}
					placeholder="Search by show or host..."
					onChange={handleInputChange}
					onKeyPress={handleKeyPress}
					onFocus={handleSearchFocus}
					onSubmit={e => { e.preventDefault() }}
				/>
			</div>
		</div>
	);
	function setUrl(searchKeyword: string) {
		if (showsOrHosts === '' || (showsOrHosts !== 'shows' && showsOrHosts !== 'hosts')) {
			history.push('/search/shows/' + searchKeyword);
		} else {
			history.push(`/search/${showsOrHosts}/${searchKeyword}`);
		}
	}
	function toggleParam(param: 'shows' | 'hosts') {
		history.push(`/search/${param}/${searchKeyword}`);
	}
};

interface ISearchToggleButton<T> {
	active: boolean;
	title: string;
	onClick?: (e?: any) => any;
}

const SearchToggleButton: React.FC<ISearchToggleButton<HTMLButtonElement>> = (props: ISearchToggleButton<HTMLButtonElement>) => {
	const { onClick, title, active } = props;
	return (
		<React.Fragment>
			<button
				onClick={onClick}
				className={classNames('nav-tabs col-6 col-lg-3 pt-2 pb-2 ', active === true ? 'active-tab' : 'inactive-tab')}
			>
				{title}
			</button>

		</React.Fragment>
	)
}


export default SearchBar;
