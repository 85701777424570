import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './common/Icons';
import withTracker from './withTracker';
import Routes from './Routes';
import './App.css';
import ProgrammeProvider from 'context/ProgrammeContext';
import PlayerProvider from 'context/PlayerContext';


const App = () => {
	return (
		<PlayerProvider>
			<ProgrammeProvider>
				<Router>
					<Route component={withTracker(Routes)} />
				</Router>
			</ProgrammeProvider>
		</PlayerProvider>
	);
}

export default App;
