import React, { useEffect, useState } from 'react';
import WbSunny from 'assets/icons/icon-wb_sunny.svg';
import WbCloud from 'assets/icons/icon-wb_cloud.svg';
import WbMoon from 'assets/icons/icon-wb_moon.svg';
import moment from 'moment';

interface IProps {

}
interface IState {
	currentGreeting: string;
	greetingMessage: string;
	iconString: string;
}

const initialState: IState = {
	currentGreeting: 'Good Morning',
	greetingMessage: 'Sip on your morning coffee while listening to your favourite show',
	iconString: WbSunny
}

const WelcomeMessages: React.FC<IProps> = () => {
    let currentHour = moment().hour();
    const [state, setState] = useState<IState>(initialState)
    useEffect(() => {
        if (currentHour >= 5 && currentHour < 12) {
            setState({
                currentGreeting: 'Good Morning',
                greetingMessage: 'Sip on your morning coffee while listening to your favourite show',
                iconString: WbSunny
            });
        } else if (currentHour >= 12 && currentHour < 18) {
            setState({
                currentGreeting: 'Good Afternoon',
                greetingMessage: 'Today is a great day to discover something new!',
                iconString: WbCloud
            });
        } else if (currentHour >= 18 || currentHour < 5) {
            setState({
                currentGreeting: 'Good Evening',
                greetingMessage: 'Wind down tonight with some of your favourite hosts',
                iconString: WbMoon
            });
		} else {
			setState(initialState);
		}
	}, [currentHour])

	return (
		<div>
			{/* <div className="d-flex align-items-center"> */}
			<h2 className="text-white">{state.currentGreeting}
				<img src={state.iconString} alt="Sunny" className="px-2" />
			</h2>
			{/* </div> */}
			<span className="text-white">{state.greetingMessage}</span>
		</div>
	);
}

export default WelcomeMessages;