import React, { useContext } from 'react';
import { ProgrammeContext } from 'context/ProgrammeContext';
import SegmentCard from './SegmentCard';
import { Link } from 'react-router-dom';

interface IProps {
}

const ExpiringSoon: React.FC<IProps> = (props) => {
	const programmeContext = useContext(ProgrammeContext);

	return (
		<div className="container mt-3 mb-5 pt-3 pb-2 border-top border-gray-200">
			<div className="row">
				<div className="col-lg-8 col-xl-12">
					<div className="d-flex justify-content-between align-items-center mb-3 ">
						<h2 className="mb-0">Expiring Soon</h2>
						<Link to="/search" className="text-primary-600">all episodes</Link>
					</div>
					<div className="row">
						{programmeContext.expiringSoonProgrammes?.map((item) => {
							// const cardKey = `expiring-soon-SegmentCard-${item.id}`;
							const cardKey = `${item.id}`;
							return (
								<div key={cardKey} className="col-xl-6">
									<SegmentCard segment={item} id={cardKey} />
								</div>
							);
						}
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExpiringSoon;
