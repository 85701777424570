import React from 'react';
import classNames from 'classnames';

interface IProps {
	className?: string;
	size?: string;
}

const Spinner: React.FC<IProps> = ( props: IProps ) => {
	return(
		<span className={classNames('spinner', props.className)} />
	);
};

export default Spinner;
