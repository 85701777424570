import React, { useContext } from 'react';
import SegmentPlaceholder from 'assets/img/Segment-Placeholder.png'
import moment from 'moment';
import classNames from 'classnames';
import PlayButton from './PlayButton';
import { ProgrammeContext } from 'context/ProgrammeContext';
import { isValidHttpUrl } from 'utils/helper';
import { PlayerContext, PlayerState } from 'context/PlayerContext';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

interface IProps {
	id: string;
	segment: SegmentView;
}

const SegmentCard: React.FC<IProps> = (props) => {
	const { id, segment } = props;

	const programmeContext = useContext(ProgrammeContext);
	const playerContext = useContext(PlayerContext);

	const now = moment();

	const expiringDate = moment(segment.expiryDate).local();
	const numberOfDaysUntilExpiry = expiringDate.endOf('day').diff(now.startOf('day'), 'days');
	const buttonIcon = (playerContext.playerState === PlayerState.playing && (playerContext.activeCardId === id || programmeContext.selectedAudio?.id + '' === id)) ? faPause : faPlay;

	const segmentImage = isValidHttpUrl(segment?.programme?.image!) ? segment?.programme?.image! : SegmentPlaceholder;
	return (
		<div className="d-flex bg-white rounded-3 mt-4 mb-n2 ms-3 card-drop-shadow" >
			<img src={segmentImage} alt="CKUA" className="img-card mt-n3 ms-n3" />
			<div className="d-flex flex-fill pe-3 align-items-center position-relative">
				<ExpiresInBadge expiresIn={numberOfDaysUntilExpiry} />
				<div className="d-flex flex-column flex-fill p-3 align-items-start">
					<span className="d-block fw-bold small">{segment.programme?.name}</span>
					<span className="d-block text-gray-700 small">{segment.hostName}</span>
					<span className="d-block small">{moment(segment.airDate).local().format('ddd, MMM DD')}</span>
				</div>
				<PlayButton
					onClick={() => programmeContext.updateSelectedAudio(id, segment)}
					icon={buttonIcon}
					iconSize="lg"
					btnClassName="btn-circle-card"
				/>
			</div>
		</div>
	);
}

interface IExpiresInBadge {
	expiresIn: number;
}

const ExpiresInBadge: React.FC<IExpiresInBadge> = (props: IExpiresInBadge) => {
	const message = props.expiresIn < 1 ? 'Expires today' : (props.expiresIn === 1 ? 'Expires in 1 day' : `Expires in ${props.expiresIn} days`);

	return (
		<span
			className={
				classNames(
					'position-absolute top-0 translate-middle-y d-block rounded-pill text-gray-700 end-0 me-2 border-1 px-2 py-0 small',
					props.expiresIn <= 3 ? 'bg-danger-100 border-danger-200 text-danger-600' : 'bg-light border-gray-300'
				)
			}
		>
			{message}
		</span>
	)
}

export default SegmentCard;