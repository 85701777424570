import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faArrowAltCircleRight as farArrowAltCircleRight,
	faEdit as farEdit,
	faFileAlt as farFileAlt,
	faStar as farStar,
	faTrashAlt as farTrashAlt,
	faThumbsUp as farThumbsUp
} from '@fortawesome/free-regular-svg-icons';
import {
	faAddressCard as fasAddressCard,
	faArrowCircleRight as fasArrowCircleRight,
	faArrowRight as fasArrowRight,
	faArrowsAlt as fasArrowsAlt,
	faCheck as fasCheck,
	faCheckCircle as fasCheckCircle,
	faChevronDown as fasChevronDown,
	faInfoCircle as fasInfoCircle,
	faMinusSquare as fasMinusSquare,
	faPlus as fasPlus,
	faPlusCircle as fasPlusCircle,
	faTimes as fasTimes,
	faTimesCircle as fasTimesCircle,
	faExclamationCircle as fasExclamationCircle,
	faHospitalSymbol as fasHospitalSymbol,
	faChalkboard as fasChalkboard,
	faExclamationTriangle as fasExclamationTriangle,
	faUsersCog,
} from '@fortawesome/free-solid-svg-icons';

library.add(
	farArrowAltCircleRight,
	farEdit,
	farFileAlt,
	farStar,
	farTrashAlt,
	farThumbsUp,
	fasArrowCircleRight,
	fasArrowRight,
	fasArrowsAlt,
	fasCheck,
	fasCheckCircle,
	fasChevronDown,
	fasInfoCircle,
	fasMinusSquare,
	fasPlusCircle,
	fasPlus,
	fasAddressCard,
	fasTimes,
	fasTimesCircle,
	fasExclamationCircle,
	fasHospitalSymbol,
	fasChalkboard,
	fasExclamationTriangle,
	faUsersCog
);