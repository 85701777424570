import * as React from 'react';
import Utils from '../../common/utils';
import { Button } from '../buttons';
import classNames from 'classnames';

// tslint:disable
const Modal = require('react-modal');
// tslint:disable

interface IProps {
	className?: string;
	headerChildren?: {};
	children?: {};
	footerChildren?: {};
	confirmButtonChildren?: {};
	cancelButtonChildren?: {};
	confirmButtonLink?: string;
	cancelButtonLink?: string;
	confirmButtonAction?: (value?: {}) => void;
	cancelButtonAction?: (value?: {}) => void;
	isOpen: boolean;
	onRequestClose?: () => any;
	hideCloseButton?: boolean;
	modalSize?: string;
}

class ModalOverlay extends React.Component<IProps> {
	public componentWillMount() {
		Modal.setAppElement('body');
	}

	public componentWillReceiveProps(nextProps: any) {
		if (nextProps.isOpen) {
			this.handleOpen();
		} else {
			this.handleClose();
		}
	}

	public componentWillUnmount() {
		this.handleClose();
	}

	public handleOpen() {
		document.body.classList.add('modal-open');
		if (window.innerHeight < document.body.offsetHeight) {
			document.body.style.paddingRight = `${Utils.getScrollbarWidth()}px`;
		}
	}

	public handleClose() {
		document.body.classList.remove('modal-open');
		document.body.style.paddingRight = '';
	}

	public render() {
		const {
			className,
			isOpen,
			children,
			onRequestClose,
			hideCloseButton,
			headerChildren,
			footerChildren,
			cancelButtonChildren,
			cancelButtonAction,
			cancelButtonLink,
			confirmButtonChildren,
			confirmButtonAction,
			confirmButtonLink,
			modalSize
		} = this.props;
		return (
			<Modal
				isOpen={isOpen}
				onRequestClose={onRequestClose}
				className={{
					base: `modal-dialog ${modalSize}`,
					afterOpen: '',
					beforeClose: ''
				}}
				overlayClassName={{
					base: classNames('modal', className),
					afterOpen: 'show',
					beforeClose: 'hide'
				}}
				closeTimeoutMS={200}
				role="dialog"
			>
				<div className="modal-content" role="document">
					{!hideCloseButton && (
						<button type="button" className="modal-close p-2" aria-label="Close" onClick={onRequestClose}>
							<span aria-hidden="true">&times;</span>
						</button>
					)}
					{headerChildren && <div className="modal-header font-weight-bold">{headerChildren}</div>}
					<div className="modal-body">{children}</div>
					{(footerChildren || cancelButtonChildren || confirmButtonChildren) && (
						<div className="modal-footer">
							{cancelButtonChildren && (
								<Button to={cancelButtonLink} onClick={cancelButtonAction} className="btn btn-outline-primary">
									{cancelButtonChildren}
								</Button>
							)}
							{confirmButtonChildren && (
								<Button to={confirmButtonLink} onClick={confirmButtonAction} className="btn btn-primary ms-3">
									{confirmButtonChildren}
								</Button>
							)}
							{footerChildren}
						</div>
					)}
				</div>
			</Modal>
		);
	}
}

export default ModalOverlay;
