export const handleShare = async (programmeName: string) => {
	let navigator1: any;
	navigator1 = window.navigator
	const url = process.env.PUBLIC_URL + '/search/shows/' + encodeURIComponent(programmeName);

	const shareData = {
		title: 'CKUA OnDemand',
		text: 'Listen to CKUA OnDemand!',
		url: url,
	}
	try {
		await navigator1.share(shareData)
		console.log('success');
	} catch (err) {
		console.log('Error: ' + err);
	}
}