import React, { useContext } from 'react';
import SegmentPlaceholder from 'assets/img/Segment-Placeholder.png'
import { ProgrammeContext } from 'context/ProgrammeContext';
import PlayButton from './PlayButton';
import { isValidHttpUrl } from 'utils/helper';
import { PlayerContext, PlayerState } from 'context/PlayerContext';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	id: string;
	featuredSegment: SegmentView;
}

const FeaturedShowCard: React.FC<IProps> = (props) => {
	const { id, featuredSegment } = props;

	const programmeContext = useContext(ProgrammeContext);
	const playerContext = useContext(PlayerContext);

	const showImage = isValidHttpUrl(featuredSegment?.programme?.image!) ? featuredSegment?.programme?.image! : SegmentPlaceholder;
	const buttonIcon = (playerContext.playerState === PlayerState.playing && (playerContext.activeCardId === id || programmeContext.selectedAudio?.id + '' === id)) ? faPause : faPlay;
	return (
		<React.Fragment>
			<div className="d-sm-flex d-lg-block">
				<div className="bg-ckua-dark position-relative rounded-3 d-flex justify-content-center overflow-hidden card-drop-shadow col-sm-6 col-lg-12">
					<img src={showImage} alt="CKUA" className="img-feature" />
					<PlayButton
						className="position-absolute top-0 end-0 mt-2 me-2"
						onClick={() => programmeContext.updateSelectedAudio(id, featuredSegment)}
						icon={buttonIcon}
					/>
				</div>
				<div className="col-sm-6 col-lg-12 pt-2 pt-sm-0 pt-lg-2 d-sm-flex d-lg-block justify-content-center flex-column ms-sm-2">
					<span className="d-block small fw-bold overflow-hidden text-wrap">{featuredSegment?.programme?.name ?? '[No Name]'}</span>
					<span className="d-block small overflow-hidden text-wrap">{featuredSegment?.hostName! ?? '[No Host]'}</span>
				</div>
			</div>
		</React.Fragment>
	);
}

export default FeaturedShowCard;

