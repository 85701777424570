import { searchHosts, searchShows } from 'utils/search';
import HostCard from 'components/ckua/HostCard';
import { ProgrammeContext } from 'context/ProgrammeContext';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IParams } from './SearchBar';
import SearchResultSection from './SearchResultSection';


interface IProps {
}

const SearchResult: React.FC<IProps> = (props) => {
	const programmeContext = useContext(ProgrammeContext);
	const params: IParams = useParams();
	const showsOrHosts = params?.parameter || '';
	const searchKeyword = decodeURIComponent(params.keyword || '');
	return (
		<React.Fragment>
			{showsOrHosts !== 'hosts' ? (
				searchShows(programmeContext.programmes || [],
					programmeContext.segments || [],
					searchKeyword)
					.map((item) => (
						<SearchResultSection
							programme={item.programme}
							segments={item.segments}
							key={`search-${item.programme.name}`}
						/>
					))
			) : (
				searchHosts(programmeContext.hosts || [], searchKeyword)
					.map((item) => {
						const keyCard = `search-hostCard-${item.id}`;
						return (
							<div className="my-2" key={keyCard}>
								<HostCard host={item} id={keyCard} simple={false} />
							</div>
						);
					})
			)}
		</React.Fragment>
	);
}

export default SearchResult;