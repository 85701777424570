import React from 'react';
import Logo from 'assets/img/logo-ckua.svg'
import { Link } from 'react-router-dom';
import { ReactComponent as FAQ } from 'assets/icons/icon-faq.svg';

const PageHeader: React.FC = () => {
	return (
		<div className="bg-dark d-flex justify-content-between">
			<Link to="/" className="d-flex align-items-center py-2 px-3 text-decoration-none">
				<img src={Logo} alt="CKUA" />
				<h6 className="text-white px-2 h4-sm font-weight-bold-sm mb-0 ">OnDemand</h6>
			</Link>
			<Link className="d-flex py-2 px-3 text-secondary align-items-center" to={{ pathname: 'https://ckua.com/ondemandfaq/' }} target="_blank" rel="noopener noreferrer">
				<span className="pe-2 d-block">FAQ</span>
				<FAQ className="" />
			</Link>
		</div>
	);
};

export default PageHeader;
