import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

const DonateButton: React.FC = () => {
	return (
		<div className="text-nowrap">
			<Link to={{ pathname: 'https://support.ckua.com/site/Donation2?1480.donation=form1&df_id=1480&mfc_pref=T'}} target="_blank" rel="noopener noreferrer">
				<Button className="btn-secondary text-dark">
					<span className="">Donate to CKUA</span>
				</Button>
			</Link>
		</div>
	);
}

export default DonateButton;