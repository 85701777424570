import * as React from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

let options = { testMode: false };
const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GOOGLE_TAG_TRACKING_ID!
}
if (window.location.href.indexOf('localhost') > 0) {
	options.testMode = true;
}
if (window.location.href.indexOf('ckuamediastorage.z5.web.core.windows.net') > 0) {
	options.testMode = true;
}
ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID!, options);
TagManager.initialize(tagManagerArgs);
// process.env.NODE_ENV === 'development' ? true : false
//GoogleAnalytics.plugin.require(process.env.REACT_APP_OPTIMIZE_ID!, null);

const withTracker = (WrappedComponent: any, options = {}) => {
	const trackPage = (page: string) => {
		ReactGA.send({ hitType: 'pageview', page: page });
	};

	const HOC = class extends React.Component<any> {
		public componentDidMount() {
			const page = this.props.location.pathname;
			trackPage(page);
		}

		public componentWillReceiveProps(nextProps: any) {
			const currentPage = this.props.location.pathname;
			const nextPage = nextProps.location.pathname;

			if (currentPage !== nextPage) {
				trackPage(nextPage);
			}
		}

		public render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

export default withTracker;