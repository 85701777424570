import { Button } from 'components/buttons';
import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import { ReactComponent as Search } from 'assets/icons/icon-search.svg';
import FeaturedShowCard from 'components/ckua/FeaturedShowCard';
import { ProgrammeContext } from 'context/ProgrammeContext';
import WelcomeMessages from 'components/ckua/WelcomeMessages';

const WelcomeBanner: React.FC = () => {
	const programmeContext = useContext(ProgrammeContext);
	return (
		<React.Fragment>
			<div className="bg-primary">
				<div className="container d-flex pt-3 pb-4">
					<WelcomeMessages />
					<div className="d-none d-sm-block ms-auto">
						<Button to="/search" className="btn-white text-dark">
							<Search />
							<span className="ms-2 d-none d-lg-inline-block">Search</span>
						</Button>
					</div>
				</div>
			</div>
			<div className="bg-primary-600 banner-border-radius">
				<div className="container pt-3 pb-lg-7">
					<div className="row">
						<div className="col-xl-10 mb-4 mb-lg-3">
							<div className="d-flex">
								<h2 className="text-white me-auto" >Featured Shows</h2>
                                <Link className="text-secondary-400" to="/search/shows">all shows</Link>
                            </div>
                            <p className="mb-0 text-white">Play the most recent recording from our feature shows</p>
                        </div>
					</div>
				</div>
			</div>
			{programmeContext.featuredProgramme && programmeContext.featuredProgramme?.length > 0 && (
				<div className="container mt-n3 mt-lg-n7 mb-3 pb-2">
					<div className="row">
						<div className="col-xl-10 px-2 px-sm-3 overflow-hidden">
							<div className="row gx-3 mb-0 card-overflow-x flex-row flex-nowrap flex-sm-wrap">
								{programmeContext.featuredProgramme?.map((item) => {
									// const cardKey = `featured-card-${item.id}`;
									const cardKey = `${item.id}`;
									return (
										<div className="col-lg-4 mb-sm-2 mb-lg-0 col-sm-12 col-8" key={cardKey}>
											<FeaturedShowCard featuredSegment={item} id={cardKey}/>
										</div>
									);
								}
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default WelcomeBanner;