import React, { useEffect } from 'react';
import { ReactComponent as IconClose } from 'components/icons/icon-close.svg';
import SearchBar from '../components/searchPage/SearchBar';
import SearchResult from '../components/searchPage/SearchResult';
import { IconLinkButton } from 'components/buttons';

interface IProps {
	className?: string;
}

const SearchPage = (props: IProps) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	return (
		<React.Fragment>
			<div className="bg-secondary banner-border-radius">
				<div className="container d-flex align-items-center align-items-center justify-content-between mb-2 pt-3 pt-lg-4 pb-7 ">
					<h2 className="mb-0">Search</h2>
					<IconLinkButton className="text-dark m-0 me-n2 p-2" to="/">
						<IconClose className="icon-size-lg"/>
					</IconLinkButton>
				</div>
			</div>
			<div className="container mt-n7">
				<SearchBar />
				<div className="row mt-4 min-vh-100">
					<div className="col-lg-8">
						<SearchResult />
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default SearchPage